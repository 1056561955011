import * as React from 'react';
import Main from '../layouts/Main';
import { useSettingsData } from '../hooks/useSettingsData';
import { FormikProps, FormikValues } from 'formik';
import { ISettingsForm } from '../types/data/settingsForm';
import SettingsForm from '../components/forms/SettingsForm';
import { settingsInitialValues } from '../validation/settingsSchema/settingsShema';
import { StoreContext } from '../store/StoreProvider';
import SettingsRightSidebar from '../components/navbars/SettingsRightSidebar';
import Loader from '../components/loader/Loader';
import Modal from 'antd/es/modal/Modal';
import OutlineButton from '../components/buttons/outlineButton/OutlineButton';
import DangerFilledButton from '../components/buttons/dangerButton/DangerFilledButton';
import { useNavigate } from 'react-router-dom';
import SumbitButton from '../components/buttons/submitButton/SubmitButton';
import Cookies from 'js-cookie';
import { BASE_PATH } from '../api/axios';

interface ISettingsProps {
}

const Settings: React.FunctionComponent<ISettingsProps> = (props) => {
  const { isLoading, settingsTranslations, mainTranslations, config, submit, deleteAccount } = useSettingsData();
  const { store, setLang, setLangIschanged, setUser } = React.useContext(StoreContext);
  const innerRef = React.useRef<FormikProps<ISettingsForm>>()
  const [initialValues, setInitialValues] = React.useState(settingsInitialValues);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState(false);
  const navigate = useNavigate();
  const closeInfoModal = () => {
    navigate('/');
  }
  const toggleDeleteModal = () => {
    setDeleteModalIsOpen(!deleteModalIsOpen)
  }
  const submitHandler = async (values: ISettingsForm) => {
    await submit(values);
    if (values.language !== store.lang) {
      setLang(values.language)
      setLangIschanged(true)
    }
  }
  const deleteHandler = async () => {
    toggleDeleteModal()
    const res = await deleteAccount();
    if (res) {
      Object.keys(Cookies.get()).forEach(function (cookieName) {
        Cookies.remove(cookieName);
      })
      setUser(null);
      navigate('/');
    }
  }
  React.useEffect(() => {
    if (store.user) {
      const { cur, lang, country, unit, request_check } = store.user
      setInitialValues({
        ...settingsInitialValues,
        country: country ?? '',
        currency: cur,
        units: unit ?? 'm',
        language: lang,
        request_status: request_check ?? 'check'
      })
    }
  }, [store.user])
  
  return <Main title={mainTranslations?.titleSettings} breadcrumbs={[
    {
      link: BASE_PATH,
      descr: mainTranslations?.breadcrumbs.home,
    },
    {
      link: "/account/profile",
      descr: mainTranslations?.breadcrumbs.profile,
    },
    {
      link: "/account/settings",
      descr: mainTranslations?.breadcrumbs.settings,
    },
  ]}>
    <div className='user-profile-content'>
      {
        config && settingsTranslations &&
        <SettingsForm innerRef={innerRef} config={config} onSubmit={submitHandler} translations={settingsTranslations} initialValues={initialValues} />
      }
    </div>
    {
      settingsTranslations &&
      <SettingsRightSidebar innerRef={innerRef} translations={settingsTranslations.sidebar} onDelete={toggleDeleteModal} />
    }
    {isLoading && <Loader text="Loading..." />}

    <Modal
      open={deleteModalIsOpen}
      onCancel={toggleDeleteModal}
      width={"30%"}
      footer={null}
      title={'Delete acount'}
    >
      <p>
        Do you really want to delete your account?
      </p>
      <div className="reply-buttons-wrapper">
        <OutlineButton text={'Cancel'} onClick={toggleDeleteModal} />
        <DangerFilledButton text={'Confirm'} onClick={deleteHandler} />
      </div>
    </Modal>
    <Modal
      open={isDeleted}
      onCancel={closeInfoModal}
      width={"30%"}
      footer={null}
      title={'Account is deleted'}
    >
      <p>
        Your account is deleted successfuly.
      </p>
      <div className="reply-buttons-wrapper">
        <SumbitButton text={'Ok'} onClick={closeInfoModal} />
      </div>
    </Modal>
  </Main>;
};

export default Settings;
