import * as React from 'react';
import NumberInput from '../../../inputs/NumberInput';
import { AdvSearchFields } from '../../../../types/emuns/adfSearchFields';
interface IPriceInputProps {
    label: string
    onChange?: (fieldName: string, value: string) => void

}

const PriceInput: React.FunctionComponent<IPriceInputProps> = (props) => {
    return <div className='adv-list-form-field'>
        <div className="label-wrapper">
            <span className='label-text'>{props.label}</span>
        </div>
        <div className="inputs-inner">
            <NumberInput name={AdvSearchFields.PRICE_MIN} placeholder='MIN' onChange={props.onChange} />
            <NumberInput name={AdvSearchFields.PRICE_MAX} placeholder='MAX' onChange={props.onChange} />
        </div>
    </div>;
};

export default PriceInput;
