import * as React from "react";
import NumberInput from "./NumberInput";

interface INumberInputWithUnitDoubleProps {
    label?: string;
    nameFirst: string;
    nameSecond: string;
    withError?: true;
    id?: string;
    image?: string;
    phFirst?: string;
    phSecond?: string;
    unitValue?: string;
    unitName: string;
    className?: string;
    area?: true;
    onChange?: (fieldName: string, value: string, unit?: string) => void
}

const NumberInputWithUnitDouble: React.FunctionComponent<
    INumberInputWithUnitDoubleProps
> = (props) => {
    const { label, nameFirst, nameSecond, image, unitName, withError, area, phFirst, phSecond, onChange } = props;
    return (
        <div className={`adv-list-form-field ${withError ? "with-error" : ""}`}>
            <div className="label-wrapper">
                <span className="label-text">{label}</span>{" "}
                {image && <img src={image} alt="" className="label-image" />}
            </div>
            <div className="inputs-inner">
                <NumberInput name={nameFirst} placeholder={phFirst} onChange={onChange} />
                <NumberInput name={nameSecond} placeholder={phSecond} onChange={onChange} />
            </div>
        </div>

    );
};

export default NumberInputWithUnitDouble;
