import * as React from 'react';
import { BASE_PATH } from '../../api/axios';
import { IListCard } from '../../types/data/requestsData';
import './advCard.scss'

interface IAdvCardProps {
    card: IListCard;
    data: string;
    price: string;
    button?: React.ReactNode
}

const AdvCard: React.FunctionComponent<IAdvCardProps> = (props) => {
    const { card, data, price, button } = props
    
    return <div className="list-card-info">
        <img
            src={BASE_PATH + card.image}
            alt=""
            className="list-card-image"
        />
        <div className="list-card-info--inner">
            <div className="list-card-id">
                ID {card.id}
                {` (${data})`}
            </div>
            <div className="list-card-type">
                {`${card.property_type} ${card.rooms} rooms ${card.area_total} ${card.area_unit}`}
            </div>
            <div className="list-card-location">
                <i className="fa-solid fa-location-dot"></i>{" "}
                {`${card.country}, ${card.city}`}
            </div>
            <div className="list-card-general">
                <div className="list-card-general-item">
                    <i className="fa-solid fa-house"></i> {card.property_type}
                </div>
                <div className="list-card-general-item">
                    <i className="fa-solid fa-stairs"></i> {card.floor}
                </div>
                <div className="list-card-general-item">
                    <i className="fa-solid fa-bed"></i> {card.bedrooms} BD
                </div>
                <div className="list-card-general-item">
                    <i className="fa-solid fa-layer-group"> </i>
                    {card.area_total} {`SQ ${card.area_unit.toUpperCase()}`}
                </div>
                <div className="list-card-general-item">
                    <i className="fa-solid fa-shower"></i> {card.bathrooms} BA
                </div>
            </div>
            <div className="list-card-price">$ {price} {card.type === 'rent' ? '/ month' : ''}</div>
        </div>
        {card.status === 4 && button && button}
    </div>;
};

export default AdvCard;
