import { array, boolean, number, object, string } from "yup";
import { IAdvInitialValues } from "../../types/data/advInitialValues";
import { phoneShema } from "../general/ptonesShema";
import { AdvFields } from "../../types/emuns/advfields";

export const advInitialValues: IAdvInitialValues = {
  images: [],
  flor_plan: [],
  type: "",
  video_link: "",
  price: "",
  price_on_request: false,
  points_of_interest: [],
  phones: [],
  description: "",
  country: "",
  city: "",
  district: "",
  address: "",
  property_type: "",
  build_category: "",
  floor: "",
  year: "",
  area_total: "",
  area_living: "",
  area_yard: "",
  area_unit: "m",
  rooms: "",
  bedrooms: "",
  bathrooms: "",
  currency: "usd",
  filters: [],
  updated_lang: "en",
};

export const poinsOfInterestsSchema = object({
  point: string().required(),
  distance: number().required(),
  unit: string(),
});
export const advShema = object({
  [AdvFields.IMAGES]: array().of(string()).min(1).required(),
  [AdvFields.FLORPLAN]: array().of(string()).nullable(),
  [AdvFields.TYPE]: string().required(),
  [AdvFields.VIDEOLINK]: string().nullable(),
  [AdvFields.PRICE]: number().required(),
  [AdvFields.PRICE_ON_REQUEST]: boolean(),
  [AdvFields.POINTS_OF_INTEREST]: array().of(poinsOfInterestsSchema).nullable(),
  [AdvFields.PHONES]: array().of(phoneShema).min(1).required(),
  [AdvFields.DESCRIPTION]: string().required(),
  [AdvFields.COUNTRY]: string().required(),
  [AdvFields.CITY]: string().min(3).required(),
  [AdvFields.DISTRICT]: string().min(3).required(),
  [AdvFields.ADDRESS]: string().min(6).required(),
  [AdvFields.PROPERTY_TYPE]: string().required(),
  [AdvFields.BUILD_CATEGORY]: string().required(),
  [AdvFields.FLOOR]: number().required(),
  [AdvFields.YEAR]: number().required(),
  [AdvFields.AREA_TOTAL]: number().required(),
  [AdvFields.AREA_LIVING]: number().required(),
  [AdvFields.AREA_YARD]: number().required(),
  [AdvFields.AREA_UNIT]: string(),
  [AdvFields.ROOMS]: number().required(),
  [AdvFields.BEDROOMS]: number().required(),
  [AdvFields.BATHROOMS]: number().required(),
  [AdvFields.FILTERS]: array().of(number()).nullable(),
});
