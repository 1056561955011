import * as React from 'react';
import { Form } from './Form';
import { IAdvSearchForm } from '../../types/data/advSearchForm';
import { advSearchInitialValues } from '../../validation/advSearchField/advSearshField';
import { AdvSearchFields } from '../../types/emuns/adfSearchFields';
import AdvListStatus from '../content/AdvList/selectcomponent/AdvListStatus';
import { IAdvListConfig } from '../../types/data/AdvListConfig';
import AdvListCountry from '../content/AdvList/selectcomponent/AdvListCountry';
import AdvListCurrency from '../content/AdvList/selectcomponent/AdvListCurrency';
import AdvListPagination from '../content/AdvList/selectcomponent/AdvListPagination';
import AdvListType from '../content/AdvList/selectcomponent/AdvListType';
import AdvListResponcePerson from '../content/AdvList/selectcomponent/AdvListResponcePerson';
import PriceInput from '../content/AdvList/listinputs/PriceInput';
import NumberInputWithUnitDouble from '../inputs/NumberInputWithUnitDouble';
import NumberInput from '../inputs/NumberInput';
import { StoreContext } from '../../store/StoreProvider';
import { IFormTranslations } from '../../types/responses/translations/formTranslations';
import useMountTransition from '../../hooks/useMountTransition';
import { tagSortHandler } from '../../utils/tagSortHandler';
import { ITagComp, SearchTags } from '../../store/advSearchFormTags';
import FormTags from '../formtag/FormTags';
import { resetFieldHandler } from '../../utils/resetFieldHandler';

interface IAdvSearchFormProps {
    innerRef: React.MutableRefObject<any>
    config: IAdvListConfig
    translations: IFormTranslations;
    onClick: (values: IAdvSearchForm) => void
}

const AdvSearchForm: React.FunctionComponent<IAdvSearchFormProps> = (props) => {
    const { innerRef, config, translations, onClick } = props
    const { store } = React.useContext(StoreContext);

    const [visible, setVisible] = React.useState(false);
    const hasTransitionedIn = useMountTransition(visible, 1000);
    const [tags, setTags] = React.useState(SearchTags);
    const [isSubmited, setIsSubmited] = React.useState(false);
    const [tagsRender, setTagsRender] = React.useState<{
        count: number;
        tags: ITagComp[]
    }>({
        count: 0,
        tags: []
    })
    const onSelectHandler = (fieldName: string, value: string) => {
        const mutable = tagSortHandler(tags, fieldName, value);
        setTags(mutable);
    }
    const onCloseHandler = async (fieldName: string, index: number) => {
        await innerRef.current?.setFieldValue(fieldName, resetFieldHandler(fieldName));
        const mutable = [...tags];
        mutable[index].value = ''
        mutable[index].value_min = '';
        mutable[index].value_max = '';
        setTags(mutable);
        if (innerRef.current.values) {
            const values = innerRef.current.values;
            if (fieldName === 'price') {
                innerRef.current?.setValues({ ...values, [AdvSearchFields.PRICE_MIN]: '', [AdvSearchFields.PRICE_MAX]: '' })
            }
            if (fieldName === 'land') {
                innerRef.current?.setValues({ ...values, [AdvSearchFields.LAND_SIZE_MIN]: '', [AdvSearchFields.LAND_SIZE_MAX]: '', })
            }
            if (fieldName === 'area') {
                innerRef.current?.setValues({ ...values, [AdvSearchFields.TOTAL_AREA_MAX]: '', [AdvSearchFields.TOTAL_AREA_MIN]: '', })
            }
            innerRef.current?.submitForm();
            return
        }
        innerRef.current?.submitForm();
    }

    const clickHandler = () => {
        setVisible(!visible)
    }
    const submitHandler = ((values: IAdvSearchForm) => {
        onClick(values)
        setIsSubmited(true);
        const deepClone = tags.map((tag) => { return { ...tag } })
        const count = deepClone.reduce((acc, tag) => {
            if (tag.tag === 'per_page' && tag.value === '10') {
                return acc
            }
            if (tag.value || tag.value_max || tag.value_min) {
                return acc += 1
            }
            return acc
        }, 0)
        onClick(values);
        setIsSubmited(true);
        setTagsRender({
            count: count,
            tags: deepClone
        })
    })
    const resetHandler = () => {
        const initial = SearchTags.map((tag) => { return { ...tag } })
        setTags(initial);
        setTagsRender({
          count: 0,
          tags: initial
        })
      }
    return <div className=''>
        {!visible &&
            <div className="filter-show-btn" onClick={clickHandler}>
                <i className="fa-solid fa-bars"></i> <span>{translations.show_filter_btn}</span>
                {
                    isSubmited && tagsRender.count > 0 &&
                    <span className='filter-count'>{tagsRender.count}</span>
                }
            </div>
        }
        <div className={`search-form-wrapper ${hasTransitionedIn ? 'in' : ''} ${visible ? 'visible' : ''}`}>
            <Form<IAdvSearchForm>
                schema={{
                    initialValues: advSearchInitialValues,
                    onSubmit: submitHandler,
                    onReset: resetHandler,

                }}
                enableReinitialize
                innerRef={innerRef}
            >
                <div className={`search-form`}>
                    <h2>{translations.titleObjects} {visible && <i className="fa-solid fa-xmark" onClick={clickHandler}></i>}</h2>
                    <div className="search-form-inner">
                        <AdvListStatus config={config.statuses} label={translations.status_label} defaultTransl={translations.all_placeholder} onChange={onSelectHandler} />
                        <AdvListCountry config={config.countries} label={translations.country_label} defaultTransl={translations.all_placeholder} onChange={onSelectHandler} />
                        <AdvListCurrency config={config.currencies} label={translations.currency_label} onChange={onSelectHandler} />
                        <PriceInput label={translations.price_label} onChange={onSelectHandler} />
                        {
                            store.user?.role !== 'private seller' &&
                            <AdvListResponcePerson label={translations.responcible_label} onChange={onSelectHandler} />
                        }
                        <AdvListType config={config.types} label={translations.property_label} defaultTransl={translations.all_placeholder} onChange={onSelectHandler} />
                        <NumberInputWithUnitDouble
                            label={translations.areatotal_label}
                            nameFirst={AdvSearchFields.TOTAL_AREA_MIN}
                            nameSecond={AdvSearchFields.TOTAL_AREA_MAX}
                            unitName={AdvSearchFields.TOTAL_AREA_UNIT}
                            phFirst="MIN"
                            phSecond="MAX"
                            area
                            className='adv-list-form-field' onChange={onSelectHandler} />
                        <NumberInputWithUnitDouble
                            label={translations.landsize_label}
                            nameFirst={AdvSearchFields.LAND_SIZE_MIN}
                            nameSecond={AdvSearchFields.LAND_SIZE_MAX}
                            unitName={AdvSearchFields.LAND_SIZE_UNIT}
                            phFirst="MIN"
                            phSecond="MAX"
                            area
                            className='adv-list-form-field' onChange={onSelectHandler} />
                        <NumberInput name={AdvSearchFields.ROOMS} label={translations.rooms_label} placeholder='amount of rooms' className='adv-list-form-field' onChange={onSelectHandler} />
                        <AdvListPagination label={translations.pagination_label} field={AdvSearchFields.PER_PAGE} onChange={onSelectHandler} />
                    </div>
                </div>
                <div className='btns-wrapper'>
                    <button type='submit' className='adv_list_submit_btn form-btn'>{translations.search_btn}</button>
                    <button type='reset' className='adv_list_reset_btn  form-btn'>{translations.clear_btn}</button>
                </div>
            </Form>
        </div>
        {
            !visible && isSubmited && tagsRender.count > 0 &&
            <FormTags config={config.tags} tags={tagsRender.tags} close={onCloseHandler} />

        }
    </div>
};

export default AdvSearchForm;
