import * as React from 'react';
import { IPrice } from '../../../../types/responses/translations/advertisementTranslations';
import './price.scss'
import { useFormikContext } from 'formik';
import { IAdvInitialValues } from '../../../../types/data/advInitialValues';
import NumberInputWithSelect from '../../../inputs/NumberInputWithSelect';
import CheckboxInput from '../../../inputs/CheckboxInput';
import Tooltip from '../../../Tooltip';
import { AdvFields } from '../../../../types/emuns/advfields';


interface IPriceModuleProps {
    translations: IPrice
}

const PriceModule: React.FunctionComponent<IPriceModuleProps> = (props) => {
    const { translations } = props;
    const [descriptionVisible, setDescriptionVisible] = React.useState(true);
    const handleClose = () => {
        setDescriptionVisible(false)
    }
    const options = [
        {
            label: 'USD',
            value: 'usd'
        },
        {
            label: 'EURO',
            value: 'euro'
        },

    ]
    const { values, setValues } = useFormikContext<IAdvInitialValues>();
    const onChange = (value: string | string[]
    ) => {
        setValues({ ...values, currency: value as string })
    };
    return <div className='price-module module line'>
        <h2 className='title'>{translations.title}</h2>
        {
            descriptionVisible && <div className='price-module-description'>
                <p>
                    {translations.description}
                </p>
                <div className='close-btn' onClick={handleClose}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
            </div>
        }
        {}
        <NumberInputWithSelect
            name={AdvFields.PRICE}
            label={translations.label}
            placeholder={translations.placeholder}
            selectName='currency'
            selectOnChange={onChange}
            selectOptions={options}
            selectSpaceWidth={100}
            selectWidth={100}
            selectDirection="vertical"
            selectValue={values.currency}
            withError
        />
        <div className='price-checkbox'>
            <CheckboxInput checkboxName={AdvFields.PRICE_ON_REQUEST} checkboxLabel={translations.checkbox} />
            <Tooltip text={translations.checkbox_info} />
        </div>
    </div>;
};

export default PriceModule;
